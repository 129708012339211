<template>
    <div class="container">
            <div class="row">
                <div class="col-12 py-4">
                    <div class="p-fluid container-fluid">
                        <Button class="p-button-raised p-button-rounded my-3 p-button-danger" icon="pi pi-arrow-left"
                            @click="goBack" />
                            
                        <div style="display:flex; justify-content: space-between; ">
                            <h3>Create Company </h3>
                            <!-- Usability Update 04-03-2024 Hide Selected Companies -->
                            <!-- <div class="mb-2 md:m-0 p-as-md-center" ><label >show <strong>Selected</strong> Companies</label> <InputSwitch v-model="form.hasPrioritary" style="margin-left: 0.7rem;"/> </div> -->
                        </div>

                        <Divider align="left">
                            <div class="inline-flex align-items-center">
                                <span class="p-tag">General Information</span>
                                <Button 
                                    class="p-button-raised p-button-rounded p-button-help mx-3" 
                                    icon="pi pi-question-circle" 
                                />
                                <!-- @click="goToHeaderPage('documentEdit-section3')"  -->
                            </div>
                        </Divider>

                        <!-- name  -->
                        <div class="row">
                            <div class="col-12">
                                <div class="field my-3">
                                    <label for="title">Name</label>
                                    <InputText id="name" v-model.trim="form.name" @blur="v$.form.name.$touch"
                                        required="true" autofocus :class="{ 'p-invalid': !form.name }" />
                                    <small class="p-error" v-if="!form.name">Name is required.</small>
                                </div>
                            </div>
                        </div>
                        <!-- Type,Country, numberOf Employees -->
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="my-3">
                                    <label for="CompanyType">Company Type</label>
                                    <Dropdown 
                                        v-model="form.companyTypeId" 
                                        :options="companyTypes" 
                                        optionLabel="name"
                                        optionValue="companyTypeId" 
                                        placeholder="Company types"
                                        :class="{ 'p-invalid': 
                                        !form.companyTypeId }" 
                                        :filter="true"
                                        :loading="loadingCompanyTypes" 
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="my-3">
                                    <label for="Country">Country</label>
                                    <Dropdown 
                                        v-model="form.countryId" 
                                        :options="countries" 
                                        optionLabel="name"
                                        optionValue="countryId" 
                                        placeholder="Country"
                                        :filter="true"
                                        :loading="loadingCountries">
                                    </Dropdown>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4">
                                <div class="my-3">
                                    <label for="numberEmployees">Number of employees</label>
                                    <!-- <InputNumber id="numberEmployees" v-model="form.numberEmployees" autofocus
                                        suffix=" employees" /> -->
                                    <InputText 
                                        type="number"
                                        min="0"
                                        v-model="form.numberEmployees"
                                    />

                                </div>
                            </div>
                        </div>

                        <!-- address -->
                        <!-- <div class="row">
                            <div class="col-12">
                                <div class="field my-3">
                                    <label for="address">Address</label>
                                    <InputText id="address" v-model.trim="form.address" autofocus />
                                </div>
                            </div>
                        </div> -->

                        <!-- notes -->
                        <div class="row">
                            <div class="col-12">
                                <label for="description">Description</label>
                                <div class="field my-3">
                                    <!-- <Textarea 
                                        style="width:100%;" 
                                        id="description" 
                                        v-model.trim="form.description"
                                        required="true" 
                                        rows="5" 
                                        cols="30" 
                                    /> -->

                                    <QuillEditor 
                                        theme="snow" 
                                        v-model:content="form.description" 
                                        :toolbar="quillToolbar"
                                        contentType="html"
                                        :style="{ 'min-height': '200px' }" 
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 p-4">
                    <!-- <div class="d-flex justify-content-end px-3">
                        <Button 
                            label="Save" 
                            icon="pi pi-check" 
                            class="p-button-success ml-3" 
                            :loading="createIsLoading"
                            :disabled="this.v$.form.$invalid || createIsLoading" @click="create" 
                        />
                    </div> -->


                    <div class="d-flex justify-content-end px-3" style="gap: 1rem;">
                        <Button 
                            class="p-button"  
                            :loading="createIsLoading"
                            :disabled="this.v$.form.$invalid || createIsLoading" 
                            @click="createAndStay" 
                        >
                            <label style="margin-right: 0.5rem;">Create Company</label>
                            <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                        </Button>

                        <Button
                            class="p-button-success"
                            :loading="createIsLoading"
                            :disabled="this.v$.form.$invalid || createIsLoading" 
                            @click="createAndGoBack" 
                        >
                            <label style="margin-right: 0.5rem;">Create Company and Go Back</label>
                            <font-awesome-icon icon="fa-regular fa-floppy-disk-circle-arrow-right" />
                        </Button>
                    </div>
                </div>
            </div>
    </div>
</template>
  
<script>
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
// import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
// import Textarea from 'primevue/textarea'
import Divider from 'primevue/divider'
import { QuillEditor } from '@vueup/vue-quill'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from 'vue-toastification';
import { createCompany } from '../services/company.service'

const toast = useToast();

export default {
    name: 'CreateCompanyPage',
    components: {
        Button,
        InputText,
        Dropdown,
        // InputNumber,
        // Textarea,
        Divider,
        QuillEditor,
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: false,
            loadingCompanyTypes: false,
            loadingCountries: false,
            createIsLoading: false,
            form: {
                name: null,
                companyTypeId: null,
                countryId: null,
                description: null,
                address: "Not Assigned",
                numberEmployees: null,
                hasPrioritary: false,
            },
            createError:false,
            companyId:null, 
            quillToolbar:[
                [{ 'header': [4,5, false] }],  // Adding heading
                ['bold', 'italic', 'underline', ], // textFormat
                [{ 'list': 'ordered' }, { 'list': 'bullet' }], // lists
            ],
        };
    },
    validations() {
        return {
            form: {
                name: { required },
                companyTypeId: { required },
            }
        };
    },
    async mounted() {
        await this.getCompanyTypes();
        await this.getCountries();
    },
    computed: {
        companyTypes() {
            return this.$store.getters.obtainCompanyTypes;
        },
        countries() {
            return this.$store.getters.obtainCountries;
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async getCompanyTypes() {
            try {
                this.loadingCompanyTypes = true;
                await this.$store.dispatch('obtainCompanyTypes');
                this.loadingCompanyTypes = false;
            } catch (error) {
                toast.error(error.message);
                this.loadingCompanyTypes = false;
            }
        },
        async getCountries() {
            try {
                this.loadingCountries = true;
                await this.$store.dispatch('obtainCountries');
                this.loadingCountries = false;
            } catch (error) {
                toast.error(error.message);
                this.loadingCountries = false;
            }
        },

        async createAndStay () {
            await this.create();
            if(!this.createError){
                // routerPush
                this.$router.push({
                    path: `./${this.companyId}`,
                    query: { fromCreated: true } // Enviar el query como un booleano
                });
            }
        },

        async createAndGoBack() {
            await this.create();
            if(!this.createError){
                this.goBack()
            }
        },

        async create() {
            this.createError = false
            try {
                if (!this.v$.form.$invalid) {
                    this.createIsLoading = true;
                    // await this.$store.dispatch('addNewCompany', this.form );
                    const companyResponse =  await createCompany(this.form);
                    this.companyId = companyResponse.data.companyId
                    
                    this.createIsLoading = false;
                    
                } else {
                    this.createError = true
                    toast.error('Wrong Company data.');
                }
            } catch (error) {
                this.createIsLoading = false;
                this.createError = true
                console.error(error);
                toast.error(error);
            }
        },
    },
}
</script>

<style>
</style>